import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

// vant
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

export default defineNuxtPlugin(({ vueApp }) => {
  // browser language
  const lang = useNavigatorLanguage()

  // vue-i18n
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'zh-CN',
    messages,
  })
  vueApp.use(i18n)

  // vant
  watchEffect(() => {
    switch (i18n.global.locale.value) {
      case 'zh-CN':
        Locale.use('zh-CN', zhCN)
        break
      default:
        Locale.use('en-US', enUS)
        break
    }
  })
})
