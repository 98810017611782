import revive_payload_client_IEHXV9RIeK from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/nuxt@3.7.4_eslint@8.50.0_rollup@2.79.1_sass@1.68.0_typescript@5.2.2_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eCNJXV3wR3 from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/nuxt@3.7.4_eslint@8.50.0_rollup@2.79.1_sass@1.68.0_typescript@5.2.2_vue-tsc@1.8.15/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WRU5ZTUYzs from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/nuxt@3.7.4_eslint@8.50.0_rollup@2.79.1_sass@1.68.0_typescript@5.2.2_vue-tsc@1.8.15/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_5zlgh0l19e from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/@nuxtjs+supabase@1.1.2_rollup@2.79.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import plugin_vue3_hRSVIB9AnA from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@2.79.1_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_kyIJFbvcLY from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/nuxt@3.7.4_eslint@8.50.0_rollup@2.79.1_sass@1.68.0_typescript@5.2.2_vue-tsc@1.8.15/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_04b57226_jAL2TeLCoX from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/.nuxt/templates.pwa.client.04b57226.ts";
import plugin_IJ7jGdMf2I from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.6_rollup@2.79.1_typescript@5.2.2_vue@3.3.4/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_client_pHjr2Drw4V from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/.nuxt/unocss.mjs";
import chunk_reload_client_7MolEV02q2 from "/Users/jelf/e8wow/e8wow-monorepo/node_modules/.pnpm/nuxt@3.7.4_eslint@8.50.0_rollup@2.79.1_sass@1.68.0_typescript@5.2.2_vue-tsc@1.8.15/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_DX069Ju2nS from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/plugins/apollo.ts";
import i18n_YCWKwfVXF6 from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/plugins/i18n.ts";
import tdesign_SZqd0dkkfT from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/plugins/tdesign.ts";
import vue_query_JC9gnf7CA1 from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/plugins/vue-query.ts";
export default [
  revive_payload_client_IEHXV9RIeK,
  unhead_eCNJXV3wR3,
  router_WRU5ZTUYzs,
  supabase_client_5zlgh0l19e,
  plugin_vue3_hRSVIB9AnA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kyIJFbvcLY,
  templates_pwa_client_04b57226_jAL2TeLCoX,
  plugin_IJ7jGdMf2I,
  plugin_client_pHjr2Drw4V,
  unocss_MzCDxu9LMj,
  chunk_reload_client_7MolEV02q2,
  apollo_DX069Ju2nS,
  i18n_YCWKwfVXF6,
  tdesign_SZqd0dkkfT,
  vue_query_JC9gnf7CA1
]