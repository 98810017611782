import { default as _91_46_46_46all_93IQcOa0ReDMMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/[...all].vue?macro=true";
import { default as _91id_93On4hE1pSaVMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/[id].vue?macro=true";
import { default as _91id_93tdVcWWtaRJMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/[id].vue?macro=true";
import { default as protocolbrUJhANZojMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/protocol.vue?macro=true";
import { default as successldtdAsMkD8Meta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/success.vue?macro=true";
import { default as _91id_93jY87tt3dOnMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/post/edit/[id].vue?macro=true";
import { default as indexCy13fhqD89Meta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/post/index.vue?macro=true";
import { default as confirm55ZFOgCRTvMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/confirm.vue?macro=true";
import { default as index7OdcBiyzABMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/index.vue?macro=true";
import { default as loginBqSic5MohtMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/login.vue?macro=true";
import { default as messagesEu7McIFIuDMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/messages.vue?macro=true";
import { default as adoption_45applyFBfcwfTRYkMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/adoption-apply.vue?macro=true";
import { default as adoption_45postPNsHTvDBKpMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/adoption-post.vue?macro=true";
import { default as collectk0FyXQRWH6Meta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/collect.vue?macro=true";
import { default as indexuYo9ByYD4cMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/index.vue?macro=true";
import { default as receive_45applynBhSdzUowPMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/receive-apply.vue?macro=true";
import { default as policyNakpooVy9tMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/policy.vue?macro=true";
import { default as store3hOOFqtasVMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/store.vue?macro=true";
import { default as termsbtuSOyruWxMeta } from "/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/terms.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93IQcOa0ReDMMeta?.name ?? "all",
    path: _91_46_46_46all_93IQcOa0ReDMMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93IQcOa0ReDMMeta || {},
    alias: _91_46_46_46all_93IQcOa0ReDMMeta?.alias || [],
    redirect: _91_46_46_46all_93IQcOa0ReDMMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93On4hE1pSaVMeta?.name ?? "adoption-id",
    path: _91id_93On4hE1pSaVMeta?.path ?? "/adoption/:id()",
    meta: _91id_93On4hE1pSaVMeta || {},
    alias: _91id_93On4hE1pSaVMeta?.alias || [],
    redirect: _91id_93On4hE1pSaVMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tdVcWWtaRJMeta?.name ?? "adoption-apply-id",
    path: _91id_93tdVcWWtaRJMeta?.path ?? "/adoption/apply/:id()",
    meta: _91id_93tdVcWWtaRJMeta || {},
    alias: _91id_93tdVcWWtaRJMeta?.alias || [],
    redirect: _91id_93tdVcWWtaRJMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/[id].vue").then(m => m.default || m)
  },
  {
    name: protocolbrUJhANZojMeta?.name ?? "adoption-apply-protocol",
    path: protocolbrUJhANZojMeta?.path ?? "/adoption/apply/protocol",
    meta: protocolbrUJhANZojMeta || {},
    alias: protocolbrUJhANZojMeta?.alias || [],
    redirect: protocolbrUJhANZojMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/protocol.vue").then(m => m.default || m)
  },
  {
    name: successldtdAsMkD8Meta?.name ?? "adoption-apply-success",
    path: successldtdAsMkD8Meta?.path ?? "/adoption/apply/success",
    meta: successldtdAsMkD8Meta || {},
    alias: successldtdAsMkD8Meta?.alias || [],
    redirect: successldtdAsMkD8Meta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/apply/success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jY87tt3dOnMeta?.name ?? "adoption-post-edit-id",
    path: _91id_93jY87tt3dOnMeta?.path ?? "/adoption/post/edit/:id()",
    meta: _91id_93jY87tt3dOnMeta || {},
    alias: _91id_93jY87tt3dOnMeta?.alias || [],
    redirect: _91id_93jY87tt3dOnMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/post/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCy13fhqD89Meta?.name ?? "adoption-post",
    path: indexCy13fhqD89Meta?.path ?? "/adoption/post",
    meta: indexCy13fhqD89Meta || {},
    alias: indexCy13fhqD89Meta?.alias || [],
    redirect: indexCy13fhqD89Meta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/adoption/post/index.vue").then(m => m.default || m)
  },
  {
    name: confirm55ZFOgCRTvMeta?.name ?? "confirm",
    path: confirm55ZFOgCRTvMeta?.path ?? "/confirm",
    meta: confirm55ZFOgCRTvMeta || {},
    alias: confirm55ZFOgCRTvMeta?.alias || [],
    redirect: confirm55ZFOgCRTvMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: index7OdcBiyzABMeta?.name ?? "index",
    path: index7OdcBiyzABMeta?.path ?? "/",
    meta: index7OdcBiyzABMeta || {},
    alias: index7OdcBiyzABMeta?.alias || [],
    redirect: index7OdcBiyzABMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBqSic5MohtMeta?.name ?? "login",
    path: loginBqSic5MohtMeta?.path ?? "/login",
    meta: loginBqSic5MohtMeta || {},
    alias: loginBqSic5MohtMeta?.alias || [],
    redirect: loginBqSic5MohtMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: messagesEu7McIFIuDMeta?.name ?? "messages",
    path: messagesEu7McIFIuDMeta?.path ?? "/messages",
    meta: messagesEu7McIFIuDMeta || {},
    alias: messagesEu7McIFIuDMeta?.alias || [],
    redirect: messagesEu7McIFIuDMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/messages.vue").then(m => m.default || m)
  },
  {
    name: adoption_45applyFBfcwfTRYkMeta?.name ?? "mine-adoption-apply",
    path: adoption_45applyFBfcwfTRYkMeta?.path ?? "/mine/adoption-apply",
    meta: adoption_45applyFBfcwfTRYkMeta || {},
    alias: adoption_45applyFBfcwfTRYkMeta?.alias || [],
    redirect: adoption_45applyFBfcwfTRYkMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/adoption-apply.vue").then(m => m.default || m)
  },
  {
    name: adoption_45postPNsHTvDBKpMeta?.name ?? "mine-adoption-post",
    path: adoption_45postPNsHTvDBKpMeta?.path ?? "/mine/adoption-post",
    meta: adoption_45postPNsHTvDBKpMeta || {},
    alias: adoption_45postPNsHTvDBKpMeta?.alias || [],
    redirect: adoption_45postPNsHTvDBKpMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/adoption-post.vue").then(m => m.default || m)
  },
  {
    name: collectk0FyXQRWH6Meta?.name ?? "mine-collect",
    path: collectk0FyXQRWH6Meta?.path ?? "/mine/collect",
    meta: collectk0FyXQRWH6Meta || {},
    alias: collectk0FyXQRWH6Meta?.alias || [],
    redirect: collectk0FyXQRWH6Meta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/collect.vue").then(m => m.default || m)
  },
  {
    name: indexuYo9ByYD4cMeta?.name ?? "mine",
    path: indexuYo9ByYD4cMeta?.path ?? "/mine",
    meta: indexuYo9ByYD4cMeta || {},
    alias: indexuYo9ByYD4cMeta?.alias || [],
    redirect: indexuYo9ByYD4cMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/index.vue").then(m => m.default || m)
  },
  {
    name: receive_45applynBhSdzUowPMeta?.name ?? "mine-receive-apply",
    path: receive_45applynBhSdzUowPMeta?.path ?? "/mine/receive-apply",
    meta: receive_45applynBhSdzUowPMeta || {},
    alias: receive_45applynBhSdzUowPMeta?.alias || [],
    redirect: receive_45applynBhSdzUowPMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/mine/receive-apply.vue").then(m => m.default || m)
  },
  {
    name: policyNakpooVy9tMeta?.name ?? "policy",
    path: policyNakpooVy9tMeta?.path ?? "/policy",
    meta: policyNakpooVy9tMeta || {},
    alias: policyNakpooVy9tMeta?.alias || [],
    redirect: policyNakpooVy9tMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: store3hOOFqtasVMeta?.name ?? "store",
    path: store3hOOFqtasVMeta?.path ?? "/store",
    meta: store3hOOFqtasVMeta || {},
    alias: store3hOOFqtasVMeta?.alias || [],
    redirect: store3hOOFqtasVMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/store.vue").then(m => m.default || m)
  },
  {
    name: termsbtuSOyruWxMeta?.name ?? "terms",
    path: termsbtuSOyruWxMeta?.path ?? "/terms",
    meta: termsbtuSOyruWxMeta || {},
    alias: termsbtuSOyruWxMeta?.alias || [],
    redirect: termsbtuSOyruWxMeta?.redirect || undefined,
    component: () => import("/Users/jelf/e8wow/e8wow-monorepo/apps/app/src/pages/terms.vue").then(m => m.default || m)
  }
]