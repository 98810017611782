<script setup lang="ts">
// import eruda from 'eruda'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

// chooseCity
import 'vant/lib/popup/style'
import 'vant/lib/cascader/style'

import { appName } from '~/constants'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Shanghai')
dayjs.locale('zh-cn')
// if (process.client && process.dev)
//   eruda.init()

useHead({
  title: appName,
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' },
  ],
})

const theme = useColorMode()
</script>

<template>
  <VitePwaManifest />
  <VanConfigProvider :theme="theme.value === 'dark' ? 'dark' : 'light'">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </VanConfigProvider>
</template>

<style>
html {
  --at-apply: color-dark bg-light;
}

html.dark {
  --at-apply: color-light bg-dark;
}
</style>
